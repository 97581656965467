/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #919191 transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #919191;
  border-radius: 8px;
}
