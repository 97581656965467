path,
ellipse,
image {
  outline: none;
}

.overlay {
  cursor: auto;
  fill: none;
  stroke: none;
}

.selection {
  cursor: auto;
  fill: none;
  stroke: gray;
}
